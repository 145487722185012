import menu from "./menu";
import meta from "./meta";
import speakers from "./speakers";
import footer from "./footer";

// speakers.sort((a, b) => a.position > b.position ? 1 : -1)

const data = {
  menu,
  meta,
  components: [
    {
      name: "Wrapper",
      components: [
        {
          name: "Tagline",
          props: {
            text1: {
              es: `<strong>Cultura digital</strong>.<br/>Comunicación.<br/>Creatividad.<br/>11ª edición.`,
              ca: `<strong>Cultura digital</strong>.<br/>Comunicació.<br/>Creativitat.<br/>11a edició.`,
              en: `<strong>Digital culture</strong>.<br/>Communication.<br/>Creativity.<br/>11th edition.`,
            },
            text2: {
              es: `Del 16 al 19<br/> de mayo de 2024.`,
              ca: `Del 16 al 19<br/> de maig de 2024.`,
              en: `From May 16th to 19th<br/> 2024.`,
            },
            textSeparatorImage: {
              src: "/wave-purple.svg",
              alt: "",
            },
            title: {
              es: `Precio`,
              ca: `Preu`,
              en: `Price`,
            },
            bubbleText: {
              es: `Edición 2025 el 2, 3 y 4 de octubre`,
              ca: `Edició 2025 el 2, 3 i 4 d'octubre`,
              en: `2025 Edition on October 2nd, 3rd and 4th`,
            },
            bubbleText2: {
              es: `En preparación`,
              ca: `En preparació`,
              en: `Getting ready`,
            },
            price: "260€",
            description: {
              es: `Hasta el 15 de enero<br>( Precio normal 260€)`,
              ca: `Fins el 15 de gener<br>( Preu normal 260€)`,
              en: `Until 15th of january<br>( regular price 260€)`,
            },
            cta: {
              title: {
                es: "Comprar entradas",
                ca: "Comprar entrades",
                en: "Shop tickets",
              },
              href: {
                es:
                  "https://esdeveniments.formentera.es/es/agenda/formentera-20/",
                en:
                  "https://esdeveniments.formentera.es/es/agenda/formentera-20/",
                ca: "https://esdeveniments.formentera.es/agenda/formentera-20/",
              },
            },
            video: {
              id: "1soxCeGPG4c",
            },
          },
        },
        {
          name: "Vimeo",
          props: {
            id: "981241334",
          },
        },
        {
          name: "About",
          props: {
            items: [
              {
                title: {
                  es: "4 días",
                  ca: "4 dies",
                  en: "4 days",
                },
                text: {
                  es:
                    "Para compartir, contactar y aprender con los mejores expertos en digital.Además de recibir inspiración, hacer networking y disfrutar… para volver a casa con  nuevas ideas, proyectos y energía.  ",
                  ca:
                    "Per a compartir, contactar i aprendre amb els millors experts en digital. A més de rebre inspiració, fer networking i gaudir… per a tornar a casa amb noves idees, projectes i energia.",
                  en:
                    "To share, contact and learn with the best experts in digital. In addition to receiving inspiration, networking and enjoy ... to return home with new ideas, projects and energy.",
                },
              },
              {
                title: {
                  es: "Talento",
                  ca: "Talent",
                  en: "Talent",
                },
                text: {
                  es:
                    "Los ponentes más relevantes a nivel nacional /internacional nos darán las claves para estar al día y afrontar nuevos retos.Formentera20, es la cita del talento digital.",
                  ca:
                    "Els ponents més rellevants a nivell nacional / internacional ens donaran les claus per a estar al dia i afrontar nous reptes.Formentera20, és la cita del talent digital.",
                  en:
                    "The most relevant speakers at national / international level will give us the keys to keep up to date and face new challenges. Formentera20, is the appointment of digital talent.",
                },
              },
              {
                title: {
                  es: "Isla",
                  ca: "Illa",
                  en: "Island",
                },
                text: {
                  es:
                    "Explorar la isla, descubrir nuevos espacios mientras aprendes y disfrutas del intercambio de ideas frente a puestas de sol inolvidables. En Formentera20 creamos experiencias únicas. ¡Vívelas!",
                  ca:
                    "Explorar l'illa, descobrir nous espais mentre aprens i gaudeixes de l'intercanvi d'idees enfront de postes de sol inoblidables. A Formentera20 creem experiències úniques. Viu-les!",
                  en:
                    "Explore the island, discover new spaces while you learn and enjoy the exchange of ideas in front of unforgettable sunsets. In Formentera20 we create unique experiences. Live them!",
                },
              },
            ],
            title: "Formentera20",
            text: {
              es: `
              <p>La cita del talento digital con los mejores expertos y en un espacio único.</p>

              <p>Charlas, debates sobre comunicación, creatividad, marketing, social media, branding, contenidos, big data…una visión 360º de la comunicación para los amantes de la cultura digital.</p>

              <p><strong>Formentera20</strong> es un evento cultural especial, por su formato reducido, la excelencia de sus ponentes y el lugar, Formentera.</p>

              <h3 class="mt-6 mb-2 text-xl font-secondary wght-700">¿A quién va dirigido?</h3>

              <p>Formentera20 es un <strong>encuentro multidisciplinar</strong> dirigido a apasionados y curiosos del entorno digital. </p>
              `,
              ca: `
              <p>La cita del talent digital amb els millors experts i en un espai únic.</p>

              <p>Xerrades, debats sobre comunicació, creativitat, màrqueting, xarxes socials, branding, continguts, big data…una visió 360º de la comunicació per als amants de la cultura digital.</p>

              <p><strong>Formentera20</strong> és un esdeveniment cultural especial, pel seu format reduït, l'excel·lència dels seus ponents i el lloc, Formentera.</p>

              <h3 class="mt-6 mb-2 text-xl font-secondary wght-700">A qui va dirigit?</h3>

              <p>Formentera20 és una <strong>trobada multidisciplinària</strong> dirigida a apassionats i curiosos de l'entorn digital. </p>
              `,
              en: `
              <p>The event of digital talent with the best experts and in a unique space.</p>

              <p>Talks about communication, creativity, marketing, social media, branding, content, big data… a 360º vision of communication for lovers of digital culture.</p>

              <p><strong>Formentera20</strong> is a special cultural event, due to its reduced format, the excellence of its speakers and the place, Formentera.</p>

              <h3 class="mt-6 mb-2 text-xl font-secondary wght-700">Who is it for?</h3>

              <p>Formentera20 is a multidisciplinary meeting aimed at passionate and curious of the digital environment.</p>
              `,
            },
          },
        },
        {
          name: "Speakers",
          props: {
            title: {
              es: "Ponentes",
              ca: "Ponents",
              en: "Speakers",
            },
            text: {
              es: `
              <p>Es la cita de referencia para los amantes del entorno digital que quieren <strong>compartir, ideas, contactar 
              y aprender</strong> con los mejores expertos en <strong>creatividad, comunicación y cultura digital</strong>.</p>

              <p>Formentera 20, una experiencia digital única,
              ¡que no te puedes perder!</p>

              <p>La filosofía de Formentera20 es aprender disfrutando, creando experiencias únicas.
              #DesconectarParaConectar</p>
              `,
              ca: `
              <p>És la cita de referència per als amants de l'entorn digital que volen compartir, idees, contactar  i aprendre amb els millors experts en creativitat, comunicació i cultura digital.</p>

              <p>Formentera20, una experiència digital única, que no et pots perdre!</p>

              <p>La filosofia de Formentera20 és aprendre gaudint, creant experiències úniques. #DesconnectarPerConnectar</p>
              `,
              en: `
              <p>It is the reference event for lovers of the digital environment who want to share, ideas, contact and learn with the best experts in creativity, communication and digital culture.</p>

              <p>Formentera 20, a unique digital experience, that you can't miss!</p>

              <p>The philosophy of Formentera20 is to learn by enjoying, creating unique experiences. #DisconnectToConnect</p>
              `,
            },
            items: speakers,
          },
        },
        {
          name: "Schedule",
          props: {
            title: {
              es: "Programa",
              ca: "Programa",
              en: "Program",
            },
            text: {
              es: `
              <p>Transformación digital. Redes Sociales. Creatividad. Multimedia. Vídeo. Marketing digital. Branding. Diseño.
              Big data. Contenidos. Comunicación. Plataformas digitales. Televisión…</p>

              <p>No te pierdas esta experiencia. ¡Vívela!</p>
              `,
              ca: `
              <p>Transformació digital. Xarxes Socials. Creativitat. Multimèdia. Vídeo. Màrqueting digital. Branding. Disseny. Big data. Continguts. Comunicació. Plataformes digitals. Televisió…</p>

              <p>No et perdis aquesta experiència. Viu-la!</p>
              `,
              en: `
              <p>Digital transformation. Social networks. Creativity. Multimedia. Video. Digital marketing. Branding. Design. Big data. Contents Communication. Digital platforms TV…</p>

              <p>Do not miss this experience. Live it!</p>
              `,
            },
            seeProgram: {
              es: "Ver programa 2024",
              ca: "Veure el programa 2024",
              en: "See program 2024",
            },
            items: [
              {
                image: "/schedule/1.jpg",
              },
              {
                image: "/schedule/2.jpg",
              },
            ],
          },
        },
        {
          name: "Tickets",
          props: {
            title: {
              es: "Entradas",
              ca: "Entrades",
              en: "Tickets",
            },
            text: {
              es: `<p>Plazas limitadas. Las dietas y desplazamiento corren a cargo de los asistentes. </p>
              <p>Si decides viajar con Trasmapi tienes un 50% de descuento en el viaje. Te enviaremos un código con más información días antes del evento.</p>
              <p>Consulta <a target="_blank" href="http://www.formentera.es">www.formentera.es</a> para información sobre alojamiento y Formentera.</p>
              `,
              ca: `<p>Places limitades. Les dietes i desplaçament són a càrrec dels assistents. </p>
              <p>Si decideixes viatjar amb Trasmapi tens un 50% de descompte en el viatge. T'enviarem un codi amb més informació dies abans de l'esdeveniment.</p>
              <p>Consulta <a target="_blank" href="http://www.formentera.es">www.formentera.es</a> per a informació sobre allotjament i Formentera.</p>
              `,
              en: `<p>Limited places. The diets and displacement are in charge of the assistants.</p>
              <p>If you decide to travel with Trasmapi you have a 50% discount on the trip. We will send you a code with more information days before the event.</p>
              <p>Check<a target="_blank" href="http://www.formentera.es">www.formentera.es</a> for information on accommodation and Formentera.</p>
              `,
            },
            text2: {
              es: `<p>*Los asistentes consienten su aparición en imágenes tomadas por cualquier tipo de medio para su posible distribución informativa, promocional y/o comercial de Formentera20 y autorizan su uso.</p>`,
              ca: `<p>*Els assistents consenten la seva aparició en imatges preses per qualsevol mena de mitjà per a la seva possible distribució informativa, promocional i/o comercial de Formentera20 i autoritzen el seu ús.</p>`,
              en: `<p>*Attendees consent to their appearance in images taken by any type of medium for their possible informative, promotional and/or commercial distribution of Formentera20 and authorize their use.</p>`,
            },
            items: [
              {
                title: {
                  es: "Pase residentes en Formentera*",
                  ca: "Pase residents a Formentera*",
                  en: "Formentera Resident Pass *",
                },
                price: "130€",
                description: {
                  es: `Entrada general
                  a todas las actividades
                  y conferencias<br><br><em>*Es necesario acreditar residencia</em>`,
                  ca: `Entrada general a totes les activitats i conferències<br><br><em>*És necessari acreditar residència</em>`,
                  en: `General admission to all activities and conferences<br><br><em>*It is necessary to prove residence</em>`,
                },
                cta: {
                  title: {
                    es: "Comprar",
                    ca: "Comprar",
                    en: "Shop",
                  },
                  href: {
                    es:
                      "https://esdeveniments.formentera.es/es/agenda/formentera-20/",
                    en:
                      "https://esdeveniments.formentera.es/es/agenda/formentera-20/",
                    ca:
                      "https://esdeveniments.formentera.es/agenda/formentera-20/",
                  },
                },
              },
              {
                title: {
                  es: "Precio normal",
                  ca: "Preu normal",
                  en: "Normal price",
                },
                price: "260€",
                description: {
                  es: `Pago online`,
                  ca: `Pagament online`,
                  en: `Online payment`,
                },
                cta: {
                  title: {
                    es: "Comprar",
                    ca: "Comprar",
                    en: "Shop",
                  },
                  href: {
                    es:
                      "https://esdeveniments.formentera.es/es/agenda/formentera-20/",
                    en:
                      "https://esdeveniments.formentera.es/es/agenda/formentera-20/",
                    ca:
                      "https://esdeveniments.formentera.es/agenda/formentera-20/",
                  },
                },
              },
            ],
          },
        },
        {
          name: "Sponsors",
          props: {
            title: {
              es: "Con el apoyo de",
              ca: "Amb el suport de",
              en: "With the support of",
            },
            cta: {
              title: {
                es: "y muchos más",
                ca: "i molts més",
                en: "and many more",
              },
              href: "sponsors",
            },
            items: [
              {
                image: "/sponsors/formentera_gray.png",
                title: "Formentera",
                url: "https://www.formentera20.com/",
              },
              {
                image: "/sponsors/caixabank.svg",
                title: "Caixabank",
                url: "https://www.caixabank.es/",
              },
              {
                image: "/sponsors/elperiódico_gray.png",
                title: "El Periódico",
                url: "https://www.elperiodico.com/es/",
              },
              {
                image: "/sponsors/vasava_gray.png",
                title: "Vasava",
                url: "https://www.vasava.es/",
                size: "w-1/3 md:1/3",
              },
              {
                image: "/sponsors/manifiesto.png",
                title: "Manifiesto",
                url: "https://manifiesto.biz/es",
              },
            ],
          },
        },
        footer,
      ],
    },
  ],
};

export default data;
